export class Invoice
{
    constructor(store)
    {
        this.store = store;       
    }   
    
    getInvoiceCheckboxStatus()
    {
        return this.invoiceCheckbox.checked;
    }
    
    clearInvoiceForm()
    {
        for (let i of this.invoiceFormFields) {
            i.value = null;
        }
    }
    
    toogleInvoiceForm()
    {        
        if (this.getInvoiceCheckboxStatus()) {
            this.invoiceForm.style.display = 'block';
        }
        else {
            this.invoiceForm.style.display = 'none';
            this.clearInvoiceForm();
        }
    }
    
    run()
    {
        document.addEventListener('DOMContentLoaded', () => {
            this.invoiceCheckbox = document.querySelector('#chce_fakture');
            this.invoiceForm = document.querySelector('#chce_fakture_form');
            if (this.invoiceForm) {
                this.invoiceFormFields = this.invoiceForm.querySelectorAll('input');
            }
            
            if (this.invoiceCheckbox) {
                this.invoiceCheckbox.addEventListener('input', () => {
                    this.toogleInvoiceForm();
                });
                this.toogleInvoiceForm();
            }
        });
    }    
}