export class BackgroundColorEffect
{
    constructor(store)
    {
        this.store = store
    }

    check()
    {
        let v_1__1 = jQuery('[data-s~="BackgroundEffect_1__1::trigger_1__1"]').visible(true)
        let v_2__1 = jQuery('[data-s~="BackgroundEffect_1__1::trigger_2__1"]').visible(true)
        let v_3__1 = jQuery('[data-s~="BackgroundEffect_1__1::trigger_3__1"]').visible(true)
        let v_4__1 = jQuery('[data-s~="BackgroundEffect_1__1::trigger_4__1"]').visible(true)

        if (v_1__1) {
            jQuery('[data-s~="BackgroundEffect_1__1::target_1__1"]').css("background-color", "#fcf3f9")
        }
        if (v_2__1) {
            jQuery('[data-s~="BackgroundEffect_1__1::target_1__1"]').css("background-color", "#e1dcea")
        }
        if (v_3__1) {
            jQuery('[data-s~="BackgroundEffect_1__1::target_1__1"]').css("background-color", "#faebf5")
        }
        if (v_4__1) {
            jQuery('[data-s~="BackgroundEffect_1__1::target_1__1"]').css("background-color", "#e7e9f4")
        }
    }

    watch()
    {
        addEventListener("load", () => {
			this.check()
		})
		addEventListener("scroll", () => {
			this.check()
		})
		addEventListener("resize", () => {
			this.check()
		})
    }
}
